export default [
    {
        path: '/pamis-production-result-type',
        name: 'pamis-production-result-type',
        component: () => import('@/views/pamis/production/productionResultType/productionResultType-list/ProductionResultTypeList.vue'),
        meta: {
            pageTitle: 'Pamis Production Result Type',
            breadcrumb: [
                {
                    text: 'Pamis Production Result Type',
                    active: true,
                },
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/pamis-production-tag',
        name: 'pamis-production-tag',
        component: () => import('@/views/pamis/production/productiontag/productionTag-list/ProductionTagList.vue'),
        meta: {
            pageTitle: 'Pamis Production Tag',
            breadcrumb: [
                {
                    text: 'Pamis Production Tag',
                    active: true,
                },
            ],
            requiresAuth: true,
        },
    },
]