export default [

  {
    path: '/pamis-organisation-home',
    name: 'pamis-organisation-home',
    component: () => import('@/views/pamis/organisation/home-view/HomeView.vue'),
    meta: {
      pageTitle: 'Pamis Organisation',
      breadcrumb: [
        {
          text: 'Pamis Organisation',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/pamis-organisation-country-list',
    name: 'pamis-organisation-country-list',
    component: () => import('@/views/pamis/organisation/country/country-list/CountryList.vue'),
    meta: {
      pageTitle: 'Country',
      breadcrumb: [
        {
          text: 'Country List',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/pamis-organisation-region-list',
    name: 'pamis-organisation-region-list',
    component: () => import('@/views/pamis/organisation/region/region-list/RegionList.vue'),
    meta: {
      pageTitle: 'Region',
      breadcrumb: [
        {
          text: 'Region List',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/pamis-organisation-client-list',
    name: 'pamis-organisation-client-list',
    component: () => import('@/views/pamis/organisation/client/client-list/ClientList.vue'),
    meta: {
      pageTitle: 'Client',
      breadcrumb: [
        {
          text: 'Client List',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/pamis-organisation-workcenter',
    name: 'pamis-organisation-workcenter',
    component: () => import('@/views/pamis/organisation/workcenter/workcenter-list/WorkCenterList.vue'),
    meta: {
      pageTitle: 'Workcenter',
      breadcrumb: [
        {
          text: 'Workcenter',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/pamis-organisation-site',
    name: 'pamis-organisation-site',
    component: () => import('@/views/pamis/organisation/site/site-list/SiteList.vue'),
    meta: {
      pageTitle: 'Site',
      breadcrumb: [
        {
          text: 'Site',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/pamis-organisation-company',
    name: 'pamis-organisation-company',
    component: () => import('@/views/pamis/organisation/company/company-list/CompanyList.vue'),
    meta: {
      pageTitle: 'Company',
      breadcrumb: [
        {
          text: 'Company',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/pamis-organisation-productionLocation',
    name: 'pamis-organisation-productionLocation',
    component: () => import('@/views/pamis/organisation/productionLocation/productionLocation-list/ProductionLocationList.vue'),
    meta: {
      pageTitle: 'Production Location Type',
      breadcrumb: [
        {
          text: 'Production Location Type',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/pamis-organisation-productionTarget',
    name: 'pamis-organisation-productionTarget',
    component: () => import('@/views/pamis/organisation/productionTarget/productionTarget-list/ProductionTargetList.vue'),
    meta: {
      pageTitle: 'Production Target',
      breadcrumb: [
        {
          text: 'Production Target',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/pamis-organisation-productionLocationDetail',
    name: 'pamis-organisation-productionLocationDetail',
    component: () => import('@/views/pamis/organisation/productionLocationDetail/productionLocationDetail-list/ProductionLocationDetailList.vue'),
    meta: {
      pageTitle: 'Production Location Detail',
      breadcrumb: [
        {
          text: 'Production Location Detail',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
]
