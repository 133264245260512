export default [
  {
    path: '/pamis-breakdown',
    name: 'pamis-breakdown',
    component: () => import('@/views/pamis/breakdown/breakdown/breakdown-list/BreakdownList.vue'),
    meta: {
      pageTitle: 'Pamis Breakdown',
      breadcrumb: [
        {
          text: 'Pamis Breakdown',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/pamis-breakdown-type',
    name: 'pamis-breakdown-type',
    component: () => import('@/views/pamis/breakdown/breakdown-type/breakdown-type-list/BreakdownTypeList.vue'),
    meta: {
      pageTitle: 'Pamis Breakdown Type Management',
      breadcrumb: [
        {
          text: 'Pamis Breakdown Type',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
]
