export default [
    {
        path: '/pamis-shift-home',
        name: 'pamis-shift-home',
        component: () => import('@/views/pamis/workcenter-shift/shift/shift-list/ShiftList.vue'),
        meta: {
            pageTitle: 'Pamis Shift',
            breadcrumb: [
                {
                    text: 'Pamis Shift',
                    active: true,
                },
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/pamis-workcenter-shift-home',
        name: 'pamis-workcenter-shift-home',
        component: () => import('@/views/pamis/workcenter-shift/workcenter-shift/workcenter-shift-list/WorkCenterShiftList.vue'),
        meta: {
            pageTitle: 'Pamis Work Center Shift',
            breadcrumb: [
                {
                    text: 'Pamis Work Center Shift',
                    active: true,
                },
            ],
            requiresAuth: true,
        },
    },
]