import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import breakdown from './breakdown'
import device from './device'
import organisation from './organisation'
import personnel from './personnel'
import production from './production'
import trend from './trend'
import workorder from './work-order'
import workcenter from './workcenter-shift'
import specParameters from './spec-parameters'
import parameters from './parameter'
import product from './product'
import mould from './mould'
import unit from './unit'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...breakdown,
    ...device,
    ...organisation,
    ...personnel,
    ...production,
    ...trend,
    ...workorder,
    ...workcenter,
    ...specParameters,
    ...parameters,
    ...product,
    ...mould,
    ...unit,
    {
      path: '/pamis',
      name: 'Pamis',
      component: () => import('@/views/pamis/organisation/home-view/HomeView.vue'),
      meta: {
        pageTitle: 'Pamis',
        breadcrumb: [
          {
            text: 'Pamis',
            active: true,
          },
        ],
      },
    },
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/home',
      name: 'PamisHome',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/LoginServer.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/callback',
      name: 'Callback',
      component: () => import('@/views/Callback.vue'),
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth) {
    store.dispatch('auth/checkAccess', requiresAuth).then(statusCode => {
      if (statusCode === 'OK') {
        next()
      } else {
        store.dispatch("auth/signin");
        return next({ name: 'login' })
      }
    })
  } else {
    next()
    // return next()
  }

  // console.log('logged in ')
  const isLoggedIn = isUserLoggedIn()
  //   console.log("forward");

  //   var forweardToLogin= localStorage.getItem('needstogoLogin');
  //  console.log("forweardToLogin "+forweardToLogin);

  //   if (forweardToLogin === "true")
  //   {
  //     console.log("in forward logged in ");
  //     if (  to.name !== 'login') {
  //       localStorage.setItem('needstogoLogin',false)
  //       console.log("in change root to logged in ");
  //       return next({ name: 'login' })
  //     }
  //   }

  // console.log('isLoggedIn', isLoggedIn)
  // console.log('Step 0')

  // if (isLoggedIn == null && (to.name == 'login' || to.name == 'choosecompany')) {
  //   console.log('Step 1')

  //   next()
  // } else if (isLoggedIn == null && to.name != 'login') {
  //   console.log('Step 2')
  //   return next({ name: 'login' })
  // } else
  // if (isLoggedIn == false && (to.name !== 'login' || to.name !== 'choosecompany')) {
  //   console.log('Step 3')
  //   return next({ name: 'login' })
  // } else {
  //   console.log('Step 4')

  //   return next()
  // }

  // if (!canNavigate(to)) {
  //   // Redirect to login if not logged in
  //   if (!isLoggedIn) return next({ name: 'auth-login' })

  //   // If logged in => not authorized
  //   return next({ name: 'misc-not-authorized' })
  // }

  // Redirect if logged in
  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  //   const userData = getUserData()
  //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  // }

  // return next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
