export default [
    {
        path: '/pamis-spec-parameters',
        name: 'pamis-spec-parameters',
        component: () => import('@/views/pamis/spec-parameters/spec-list/SpecList.vue'),
        meta: {
            pageTitle: 'Spec',
            breadcrumb: [
                {
                    text: 'Pamis Spec',
                    active: true,
                },
            ],
            requiresAuth: true,
        },
    }
]