export default [
    {
        path: '/pamis-mould',
        name: 'pamis-mould',
        component: () => import('@/views/pamis/work-order/mould/mould-list/MouldList.vue'),
        meta: {
            pageTitle: 'Mould',
            breadcrumb: [
                {
                    text: 'Pamis Mould',
                    active: true,
                },
            ],
            requiresAuth: true,
        },
    }
]