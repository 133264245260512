export default [
    {
        path: '/pamis-device',
        name: 'pamis-device',
        component: () => import('@/views/pamis/device/device/device-list/DeviceList.vue'),
        meta: {
            pageTitle: 'Pamis Device',
            breadcrumb: [
                {
                    text: 'Pamis Device',
                    active: true,
                },
            ],
            requiresAuth: true,
        },
    }
]