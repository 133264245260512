export default [
    {
        path: '/pamis-personnel',
        name: 'pamis-personnel',
        component: () => import('@/views/pamis/personnel/personnel-list/PersonnelList.vue'),
        meta: {
            pageTitle: 'Personnel',
            breadcrumb: [
                {
                    text: 'Pamis Personnel',
                    active: true,
                },
            ],
            requiresAuth: true,
        },
    }
]