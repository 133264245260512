export default [
    {
        path: '/pamis-parameter',
        name: 'pamis-parameter',
        component: () => import('@/views/pamis/parameter/parameter-list/ParameterList.vue'),
        meta: {
            pageTitle: 'Parameters',
            breadcrumb: [
                {
                    text: 'Pamis Parameters',
                    active: true,
                },
            ],
            requiresAuth: true,
        },
    }
]